import React from "react";

const SignUp = () => {
    return(
    <div className="n-signup" style={{display:"flex",alignItems:"center",justifyContent:"center", height:"100vh"}}>
        <p style={{fontSize:"2.5rem", fontFamily:"cursive", textAlign:'center'}}>The registration has been closed. <br/> You may <a href="mailto:support@znotes.in">contact the admin </a>for further queries </p>
    </div>
    
)}

export {SignUp};
